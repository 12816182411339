<template>
  <v-sheet
    class="mb-5"
    color="transparent"
  >
    <v-row class="mx-0">
      <v-sheet
        class="mr-3"
        color="primary"
        width="1"
        height="100"
        tile
      />

      <v-col>
        <div
          class="mb-3"
          v-text="value.institution"
        />

        <div class="text-right">
          <span
            class="text-h6 primary--text"
            v-text="`${value.studyType} of ${value.area}`"
          />

          <footer>
            <div
              v-for="(course, i) in value.courses"
              :key="i"
              v-text="course"
            />
            <div v-text="date" />
          </footer>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
  import format from 'date-fns/format'
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          institution: '',
          area: '',
          studyType: '',
          endDate: '',
          gpa: '',
          courses: [],
        }),
      },
    },

    computed: {
      date () {
        return format(new Date(`
          ${this.value.endDate}
        `), 'MMMM yyyy')
      },
    },
  }
</script>
